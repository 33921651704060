.application-form {
  background-color: #2c3e50;
  min-height: 100vh;
  padding: 20px;
}

.content-container {
  padding: 20px;
  margin-top: 5em;
}

.info-card {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.footer {
  background-color: #2c3e50;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}
