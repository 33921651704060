.registration-form-container {
    max-width: 600px; /* Set max width for the form */
    margin: 0 auto; /* Center the form */
    padding: 20px; /* Add padding */
    background-color: #f9f9f9; /* Light background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  }
  
  h2 {
    text-align: center; /* Center the heading */
    color: #2596be; /* Heading color */
    margin-bottom: 20px; /* Space below heading */
  }
  
  .ant-form-item {
    margin-bottom: 16px; /* Space between form items */
  }
  
  @media (max-width: 768px) {
    .registration-form-container {
      padding: 10px; /* Adjust padding on smaller screens */
    }
  }
  