.features-post .content-hide {
    display: none;
    /* background-color: yellow; */
    color: white;
    padding: 15px;
    border-radius: 5px;
  }
  
  .features-post:hover .content-hide {
    display: block;
  }
  
  .features-post .content-show {
    background-color: #333;
    color: white;
    padding: 15px;
    cursor: pointer;
  }
  .main-banner {
    position: relative;
    overflow: hidden;
  }
  
 
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
  }
  
  .caption h6 {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .caption h2 {
    font-size: 2.5em;
    font-weight: bold;
    margin: 0;
  }
  
  .caption p {
    font-size: 1em;
    margin: 15px 0;
  }
  
  .main-button .btn {
    background-color: darkblue;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .features {
    background-color: #f0f8ff; /* Adjust the background color as needed */
    padding: 40px 0;
  }
  
  .feature-card {
    background-color: #d32f2f; /* The red background color */
    color: #fff; /* White text */
    border: none;
    border-radius: 8px;
    transition: transform 0.3s;
  }
  
  .feature-card:hover {
    transform: translateY(-5px); /* Slight hover effect */
  }
  
  .icon-circle {
    background-color: darkblue; /* Darker shade of red for the icon background */
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 15px auto;
  }
  
  .icon-circle i {
    font-size: 24px;
    color: #fff;
  }
  
  .card-title {
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .card-text {
    font-size: 0.95em;
    margin-bottom: 20px;
  }
  
  .btn-outline-light {
    border: 1px solid #fff;
    color: #fff;
    padding: 8px 16px;
    transition: background-color 0.3s;
  }
  
  .btn-outline-light:hover {
    background-color: #fff;
    color: #d32f2f;
  }
  
  
  /* Style for the service cards */
.service-card {
    margin-bottom: 30px;
    transition: transform 0.2s;
  }
  
  .service-card:hover {
    transform: scale(1.05);
  }
  
  /* Center the section heading */
  .section-heading {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .section-heading h2 {
    font-size: 2.5rem;
    font-weight: bold;
    /* color: #333; */
  }
  
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  /* Footer styling */
  footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  footer i {
    margin-right: 5px;
  }
  .section.why-us {
    margin-top: 100px;
    padding: 60px 0;
    background-color: #f7f7f7;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .entry {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .entry-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .entry-title a {
    color: #333;
    text-decoration: none;
  }
  
  .entry-content p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .read-more a {
    display: inline-block;
    margin-top: 10px;
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 5px;
  }
  .card-img img {
    object-fit: cover;
    height: 250px; /* Set a consistent height */
    width: 100%;
  }

  @media (max-width: 768px) {
    .card-img img {
      height: 200px; /* Adjust height for smaller screens */
    }
  }
  .contact-container {
    background-color: #f5f5f5;
    padding: 50px;
    min-height: 100vh;
    margin-top: 8em;
  }
  
  .ant-form-item-label > label {
    font-weight: bold;
  }
  
  h2 {
    color: #2596be;
  }
  
  button.ant-btn-primary {
    background-color: #2596be;
    border-color: #2596be;
  }
  
  button.ant-btn-primary:hover {
    background-color: #1d7fa6;
    border-color: #1d7fa6;
  }
  